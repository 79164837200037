import * as AWS from "aws-sdk"
import axios from "axios"
import { v4 as uuidv4 } from "uuid"

const savingOnAmazon = (file: Blob | File, uuid: string, ext: string) =>
  axios
    .get(
      "https://daqin63l67.execute-api.eu-central-1.amazonaws.com/testing/message/voice/temp-access"
    )
    .then((response) => {
      // Keys returned from STS
      AWS.config.credentials = new AWS.Credentials(
        response.data.body.ACCESS_KEY,
        response.data.body.SECRET_KEY,
        response.data.body.SESSION_TOKEN
      )
      AWS.config.update({ region: "eu-central-1" })
      const params = {
        Body: file,
        Bucket: "mxney-messenger",
        Key: `${uuid}.${ext}`,
        /*ServerSideEncryption: "AES256",*/
      }

      const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
      })

      s3.putObject(params, (err) => {
        if (err) {
          console.log(err, err.stack)
        }
      })
    })
    .catch((e) => {
      console.log(e)
    })

export type SendMedia = {
  files: any[]
  uuid: string[]
  ext: string
}

export const sendMedia = (
  files: Blob[] | File[],
  doneRecording: (data: SendMedia) => void,
  ext = ""
) => {
  const uuid: string[] = []
  files.forEach((file: any, i: number) => {
    const extension = ext || `${file.name.split(".").pop()}`
    const id = uuidv4()
    uuid[i] = id
    savingOnAmazon(file, id, extension)
  })
  doneRecording({ files, uuid, ext })
}
