export { Chat } from "./Chat"

export const colorFromType = {
  "growth pack service": "yellow",
  growth: "black",
  finance: "blue",
  legal: "green",
  tech: "purple",
  "": "#828282",
}

export type ColorFromType = keyof typeof colorFromType
