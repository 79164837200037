import { TooltipButton } from "App/components/ui/TooltipButton"
import React, { FC, memo } from "react"
import { Modal, ModalBody } from "reactstrap"

type Props = {
  id: string
  icon: JSX.Element
  tooltip: string
  isModal: boolean
  toggleModal: (isModal: boolean) => void
}

export const MessageModal: FC<Props> = memo(
  ({ children, id, icon, tooltip, toggleModal, isModal }) => (
    <>
      <TooltipButton
        onClick={() => toggleModal(true)}
        id={id}
        icon={icon}
        tooltip={tooltip}
        color="link"
      />
      {isModal && (
        <Modal isOpen={isModal} toggle={() => toggleModal(!isModal)} centered>
          <ModalBody>{children}</ModalBody>
        </Modal>
      )}
    </>
  )
)
