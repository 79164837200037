import { apiClient } from "App/components/services/api"
import { echo } from "App/components/services/webscokets"

import { updateUnreadMessage } from "./store"

type MediaObject = {
  name: string
  meta_data: {
    fileName: string
    fileSize: string
    fileExtension?: string
  }
}

export type SendingValue = {
  message: string
  type: string
  attachments?: MediaObject[]
}

export const sendMessage = (groupId: number, { message, type, attachments = [] }: SendingValue) =>
  apiClient.post(
    `/api/groups/${groupId}/messages`,
    {
      message,
      type,
      attachments,
    },
    {
      headers: {
        "X-Socket-Id": echo.socketId(),
      },
    }
  )

export const getMessages = (page: number, id: number) =>
  apiClient.get(`/api/groups/${id}/messages`, {
    params: {
      page,
    },
  })

export const readMessages = (id: number) =>
  apiClient.post(`/api/groups/${id}/read`).then(() => updateUnreadMessage({ groupId: id }))

export const sendMessageTemplate = ({ message, type, attachments = [] }: SendingValue) =>
  apiClient.post(
    "/api/message-template",
    {
      message,
      type,
      attachments,
    },
    {
      headers: {
        "X-Socket-Id": echo.socketId(),
      },
    }
  )

export const getMessageTemplate = () => apiClient.get("/api/message-template")
