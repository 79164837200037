import { Message, TEXT_MESSAGE } from "App/Chat/types"
import React from "react"

import { MediaMessage } from "./MediaMessage"
import { TextMessage } from "./TextMessage"

export const MessageComponent = ({
  message,
  isOutgoingMessage,
  stopHover,
}: {
  message: Message
  isOutgoingMessage: boolean
  stopHover?: boolean
}) =>
  message.media === TEXT_MESSAGE ? (
    <TextMessage stopHover={stopHover} message={message} isOutgoingMessage={isOutgoingMessage} />
  ) : (
    <MediaMessage stopHover={stopHover} message={message} isOutgoingMessage={isOutgoingMessage} />
  )
