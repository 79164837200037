import { apiClient } from "App/components/services/api"

export const errorHandler = (key: number, updateKey: (key: number) => void) =>
  key < 6 && setTimeout(() => updateKey(key + 1), 100 * Math.pow(3, key))

export const downloadFIle = (link: string, fileName: any) => {
  apiClient.get(link).then(({ data }: { data: { ext: string; base64: string } }) => {
    const linkSource = `data:application/${data.ext};base64,${data.base64}`
    const downloadLink = document.createElement("a")

    document.body.appendChild(downloadLink)

    downloadLink.target = "_self"
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  })
}
