import { apiClient } from "App/components/services/api"

export type Users = {
  email: string
  id: number
  mxney_staff: boolean
  name: string
}[]

export const getUsers = async (value: string): Promise<Users> => {
  const { data } = await apiClient.get("/api/users", {
    params: {
      q: value,
    },
  })

  return data.data
}

type Group = {
  name: string
  display_names: { [id: number]: string }
  first_message: string
}

export const setGroup = (group: Group) => apiClient.post("/api/groups", group)
export const getGroup = (groupId: number) => apiClient.get(`/api/groups/${groupId}`)
export const updateGroup = (groupId: number, data: any) =>
  apiClient.put(`/api/groups/${groupId}`, data)
