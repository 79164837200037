import React, { useState } from "react"
import * as FeatherIcon from "react-feather"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

export const FriendsDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="span">
        <FeatherIcon.MoreHorizontal />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>New chat</DropdownItem>
        <DropdownItem>Profile</DropdownItem>
        <DropdownItem divider />
        <DropdownItem>Block</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
