import styled from "@emotion/styled"
import { TooltipButton } from "App/components/ui/TooltipButton"
import React, { FormEvent, useState } from "react"
import * as FeatherIcon from "react-feather"

import { AudioMessageModal, FileMessageModal, VideoMessageModal } from "./MediaMessageModals"
import { SendingValue } from "./api"
import { OUTGOING_TEXT_MESSAGE } from "./types"

type Props = {
  sendMessage: (value: SendingValue) => void
  onFocus?: () => void
  defaultMessages?: JSX.Element
}
export const ToolBar = ({ sendMessage, onFocus, defaultMessages }: Props) => {
  const [inputMsg, setInputMsg] = useState("")

  const onSubmit = (value: SendingValue) => {
    sendMessage(value)
    setInputMsg("")
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    inputMsg &&
      onSubmit({
        message: inputMsg,
        type: OUTGOING_TEXT_MESSAGE,
      })
  }

  const handleChange = (e: any) => {
    setInputMsg(e.target.value)
  }

  const onKeyDownHandler = (e: any) => {
    const shiftEnter = e.key === "Enter" && e.shiftKey
    if (e.key === "Enter" && !shiftEnter) {
      handleSubmit(e)
    }
  }

  return (
    <Form>
      <Textarea
        onFocus={onFocus}
        placeholder="Write a message."
        value={inputMsg}
        onChange={handleChange}
        onKeyDown={onKeyDownHandler}
      />
      <FormButtons>
        {defaultMessages}
        <FileMessageModal onSubmit={onSubmit} />
        <VideoMessageModal onSubmit={onSubmit} />
        <AudioMessageModal onSubmit={onSubmit} />
        <SendMessageButton onClick={handleSubmit} color="primary" icon={<FeatherIcon.Send />} />
      </FormButtons>
    </Form>
  )
}

const Form = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

const FormButtons = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
`

const SendMessageButton = styled(TooltipButton)`
  margin-left: 8px;
`
