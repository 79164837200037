import { getGroupData } from "App/GroupModal/store"
import React, { useState } from "react"
import * as FeatherIcon from "react-feather"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

export const ChatsDropdown = ({ groupId }: { groupId: number }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const showGroupEditModal = (e: any) => {
    e.stopPropagation()
    getGroupData(groupId)
  }

  return (
    <Dropdown
      isOpen={dropdownOpen}
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
    >
      <DropdownToggle tag="span">
        <FeatherIcon.MoreHorizontal />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem toggle={false} onClick={showGroupEditModal}>
          Edit group
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
