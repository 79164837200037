import { createStore, combineReducers, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"

import { mobileProfileSidebarReducer } from "./Reducers/mobileProfileSidebarReducer"
import { mobileSidebarReducer } from "./Reducers/mobileSidebarReducer"
import { pageTourReducer } from "./Reducers/pageTourReducer"
import { profileSidebarReducer } from "./Reducers/profileSidebarReducer"
import { sidebarReducer } from "./Reducers/sidebarReducer"

const reducers = combineReducers({
  selectedSidebar: sidebarReducer,
  mobileSidebar: mobileSidebarReducer,
  profileSidebar: profileSidebarReducer,
  mobileProfileSidebar: mobileProfileSidebarReducer,
  pageTour: pageTourReducer,
})

export type RootStore = {
  selectedSidebar: string
  mobileSidebar: boolean
  profileSidebar: boolean
  mobileProfileSidebar: boolean
  pageTour: boolean
}

export const store = createStore(reducers, composeWithDevTools(applyMiddleware()))
