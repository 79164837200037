import styled from "@emotion/styled"
import React from "react"
import { Badge } from "reactstrap"

export const VideoMessageNotSupport = () => (
  <>
    Video messages are not supported in this browser. Please install Google chrome
    <Link
      color="success"
      onClick={() => window.open("https://www.google.com/intl/en/chrome/", "_blank")}
    >
      here
    </Link>
  </>
)

const Link = styled(Badge)`
  cursor: pointer;
  margin-left: 8px;
`
