import { groupTypes, selectItem } from "App/GroupModal"
import { Select } from "App/components/ui/Select"
import React, { useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

export const FilterGroupsModal = ({
  isOpen,
  toggle,
  filterValue,
  setFilterValue,
}: {
  isOpen: boolean
  toggle: Function
  filterValue: selectItem
  setFilterValue: Function
}) => {
  const [selectedFilter, setSelectedFilter] = useState(filterValue)

  const toggleModal = () => toggle()

  const onTypeChange = (type: selectItem) => setSelectedFilter(type)

  const closeModal = () => {
    setFilterValue(selectedFilter)
    toggleModal()
  }

  return (
    <Modal className="modal-dialog-zoom" isOpen={isOpen} toggle={toggleModal} centered>
      <ModalHeader>Filter groups</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="type">Group type</Label>
            <Select
              selectedOption={selectedFilter}
              onChange={onTypeChange}
              placeholder="Filter group type"
              options={groupTypes}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={closeModal}>
          Filter
        </Button>
      </ModalFooter>
    </Modal>
  )
}
