import "assets/scss/Index.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import { attachLogger } from "effector-logger/attach"
import { root } from "effector-root"
import "font-awesome/css/font-awesome.min.css"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import { App } from "./App"
import { store } from "./Store"
import * as serviceWorker from "./serviceWorker"

attachLogger(root, {
  console: "enabled",
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
