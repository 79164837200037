import styled from "@emotion/styled"
import { SendingValue } from "App/Chat/api"
import { OUTGOING_FILE_MESSAGE } from "App/Chat/types"
import { TooltipButton } from "App/components/ui/TooltipButton"
import React, { Fragment, memo, useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import * as FeatherIcon from "react-feather"

import { MessageModal } from "./MessageModal"
import { SendMedia, sendMedia } from "./api"

const readFileAsync = (file: File) =>
  new Promise<File>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(file)
    }

    reader.onerror = reject

    reader.readAsArrayBuffer(file)
  })

const getFileSize = (bites: number) => {
  const kb = Math.round(bites * 0.0009765625 * 100) / 100
  const mb = () => Math.round(bites * 0.00000095367431640625 * 100) / 100

  return kb > 1024 ? `${mb()}MB` : `${kb}KB`
}

type Props = {
  onSubmit: (newValue: SendingValue) => void
}

const Dropzone = memo(({ onSubmit, closeModal }: Props & { closeModal: () => void }) => {
  const [files, setFile] = useState<File[] | []>([])

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const files = acceptedFiles.map(async (acceptedFile: File) => await readFileAsync(acceptedFile))
    Promise.all(files).then((res: File[]) => {
      setFile(res)
    })
  }, [])

  const sendFile = ({ files, uuid }: SendMedia) => {
    const attachments = files.map((file: File, i: number) => ({
      name: uuid[i],
      meta_data: {
        fileName: file.name,
        fileSize: getFileSize(file.size),
        fileExtension: `${file.name.split(".").pop()}`,
      },
    }))

    files.length &&
      onSubmit({
        message: "",
        type: OUTGOING_FILE_MESSAGE,
        attachments,
      })
  }

  const handleSend = () => {
    if (files.length) {
      sendMedia(files, sendFile)
      closeModal()
    }
  }

  const removeFile = (files: File[], index: number) => {
    files.splice(index, 1)
    setFile([...files])
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <FileInputWrap {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </FileInputWrap>
      <TooltipButtonStyled onClick={handleSend} text="Send" />
      <br />
      <br />
      {files.map((file: File, i: number, array: any) => (
        <Fragment key={i}>
          <FeatherIcon.X
            color="red"
            onClick={() => removeFile(array, i)}
            style={{ cursor: "pointer" }}
          />
          {i + 1}. <span>{file.name}</span>
          <br />
        </Fragment>
      ))}
    </>
  )
})

export const FileMessageModal = ({ onSubmit }: Props) => {
  const [isModal, toggleModal] = useState(false)

  return (
    <MessageModal
      id="Tooltip-File-Message"
      icon={<FeatherIcon.Paperclip />}
      tooltip="File Message"
      isModal={isModal}
      toggleModal={toggleModal}
    >
      <Dropzone onSubmit={onSubmit} closeModal={() => toggleModal(false)} />
    </MessageModal>
  )
}

const FileInputWrap = styled.div`
  height: 200px;
  width: 100%;
  border: 1px dashed #333;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`

const TooltipButtonStyled = styled(TooltipButton)`
  margin-right: 16px;
`
