import styled from "@emotion/styled"
import { MessagesView } from "App/Chat/MessagesView"
import { sendMessage } from "App/Chat/api"
import { setOutgoingMessage } from "App/Chat/store"
import { Message } from "App/Chat/types"
import { TooltipButton } from "App/components/ui/TooltipButton"
import { useStore } from "effector-react"
import React from "react"
import * as FeatherIcon from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import { RouteComponentProps, withRouter } from "react-router-dom"

import { defaultMessagesStore } from "./store"

export const DefaultMessagesBody = withRouter(({ match }: RouteComponentProps<{ id?: string }>) => {
  const templates = useStore(defaultMessagesStore)

  return (
    <PerfectScrollbar>
      <div className="chat-body">
        <div className="messages">
          {Object.keys(templates).map((templateType, i) => {
            if (templates[templateType].messages.length) {
              return (
                <MessagesBlock key={i}>
                  <MessagesBlockTitle>{templates[templateType].title}</MessagesBlockTitle>
                  {templates[templateType].messages.map((message, messageIndex) => (
                    <MessagesWrap
                      key={message.id}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    >
                      <MessagesView stopHover message={message} sameUser={1} />
                      <SendButton
                        id={`send-default-message-button-${i}-${messageIndex}`}
                        onClick={() => {
                          sendMessage(Number(match.params.id), {
                            type: `outgoing-${message.media}`,
                            message: message.text,
                            attachments: message.attachments.data,
                          }).then(({ data }: { data: { data: Message } }) => {
                            setOutgoingMessage(data.data)
                          })
                        }}
                        tooltip="send message"
                        icon={<FeatherIcon.CornerUpLeft />}
                        color="link"
                      />
                    </MessagesWrap>
                  ))}
                </MessagesBlock>
              )
            }
          })}
        </div>
      </div>
    </PerfectScrollbar>
  )
})

const MessagesBlock = styled.div`
  margin-bottom: 16px;
  width: 100%;
`

const MessagesBlockTitle = styled.h5``

const MessagesWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const SendButton = styled(TooltipButton)`
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 12px;

  &:hover {
    opacity: 0.7;
  }
`
