import { Navigation } from "App/Chat/Navigation"
import { chatInit, checkVideoSupported } from "App/Chat/store"
import { DisconnectedModal } from "App/Modals/DisconnectedModal"
import { TourModal } from "App/Modals/TourModal"
import { Sidebar } from "App/Sidebars"
import { Profile } from "App/Sidebars/Profile"
import { setUser } from "App/SignIn/store"
import { apiClient } from "App/components/services/api"
import { tourSteps } from "App/components/tour"
import { RootStore } from "Store"
import { pageTourAction } from "Store/Actions/pageTourAction"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Tour from "reactour"

import { ChatBody } from "./ChatBody"

export const Chat = () => {
  const { pageTour } = useSelector((state: RootStore) => state)

  const dispatch = useDispatch()

  useEffect(() => {
    checkVideoSupported()
    apiClient.get("/api/user").then(({ data }) => {
      setUser(data.data)
    })
    chatInit()
    const clickHandler = (e: any) => {
      if (document.body.classList.contains("navigation-open") && e.target?.nodeName === "BODY") {
        document.body.classList.remove("navigation-open")
      }
    }

    document.querySelector("*")?.addEventListener("click", clickHandler)
    return () => {
      document.querySelector("*")?.removeEventListener("click", clickHandler)
    }
  }, [])

  return (
    <div className="layout">
      <Tour
        steps={tourSteps}
        isOpen={pageTour}
        onRequestClose={() => dispatch(pageTourAction(false))}
      />
      <div className="content">
        <Navigation />
        <Sidebar />
        <ChatBody />
        <Profile />
        <TourModal />
        <DisconnectedModal />
      </div>
    </div>
  )
}
