import styled from "@emotion/styled"
import { Select } from "App/components/ui/Select"
import { useStore } from "effector-react"
import React, { memo, useCallback, useState } from "react"
import * as FeatherIcon from "react-feather"
import { Input, InputGroupAddon } from "reactstrap"

import { getUsers } from "./api"
import { addUser, createGroupModalStore, removeUser, setUserId, setUserName } from "./store"

export type AvailableUsers = {
  label: string
  value: string
}[]

type Props = {
  isFirst: boolean
  id: number
  name: string
  index: number
  label: string
  isError?: boolean
}

export const GroupMember = memo(({ isFirst, name, index, label, isError }: Props) => {
  const [availableUsers, setAvailableUsers] = useState<AvailableUsers | []>([])
  const { usersInGroup } = useStore(createGroupModalStore)

  const onInputChange = useCallback((value: string) => {
    if (value) {
      getUsers(value).then((users) => {
        const availableUsers = users.reduce((accum: AvailableUsers | [], { id, name }) => {
          const isUserPresent = usersInGroup.some((user) => user.id === id)
          return isUserPresent ? accum : [...accum, { value: String(id), label: name }]
        }, [])
        setAvailableUsers(availableUsers)
      })
    }
  }, [])

  const onChange = useCallback(
    ({ value, label }) => {
      setUserId({ index, id: Number(value), label })
    },
    [index]
  )

  return (
    <GroupMemberWrap addonType="append" className="mb-2">
      <Select
        selectedOption={{ label }}
        onChange={onChange}
        onInputChange={onInputChange}
        placeholder="Select user"
        options={availableUsers}
        isError={isError}
      />
      <UserNick
        placeholder="Display name"
        type="text"
        value={name}
        onChange={(e) => setUserName({ name: e.target.value, index })}
      />
      {isFirst ? (
        <ToggleUser onClick={() => addUser()}>
          <FeatherIcon.UserPlus className="mr-2" />{" "}
        </ToggleUser>
      ) : (
        <ToggleUser onClick={() => removeUser(index)}>
          <FeatherIcon.UserMinus className="mr-2" />{" "}
        </ToggleUser>
      )}
    </GroupMemberWrap>
  )
})

const ToggleUser = styled.div`
  width: 60px;
  flex-shrink: 0;
  cursor: pointer;
`

const UserNick = styled(Input)`
  margin: 0 16px;
  height: 2.3rem;
`

const GroupMemberWrap = styled(InputGroupAddon)`
  align-items: center;
`
