import { AUDIO_MESSAGE, FILE_MESSAGE, Message, TEXT_MESSAGE, VIDEO_MESSAGE } from "App/Chat/types"
import { createStore, createEvent } from "effector"

export type TemplateMessage = Omit<Message, "name, avatar, group_id">
type Store = {
  [key: string]: {
    title: string
    messages: TemplateMessage[]
  }
}

const defaultStore: Store = {
  [TEXT_MESSAGE]: {
    title: "Text messages",
    messages: [] as TemplateMessage[],
  },
  [AUDIO_MESSAGE]: {
    title: "Audio messages",
    messages: [],
  },
  [VIDEO_MESSAGE]: {
    title: "Video messages",
    messages: [],
  },
  [FILE_MESSAGE]: {
    title: "File messages",
    messages: [],
  },
}

export const setDefaultMessages = createEvent<TemplateMessage[]>()
export const setDefaultOutgoingMessage = createEvent<TemplateMessage>()

export const defaultMessagesStore = createStore(defaultStore)
  .on(setDefaultOutgoingMessage, (store, message) => ({
    ...store,
    [message.media]: {
      ...store[message.media],
      messages: [...store[message.media].messages, message],
    },
  }))
  .on(setDefaultMessages, (store, messages) => {
    const templates = messages.reduce(
      (accum, message) => ({
        ...accum,
        [message.media]: {
          ...accum[message.media],
          messages: [...accum[message.media].messages, { ...message, text: message.text || "" }],
        },
      }),
      defaultStore
    )

    return {
      ...store,
      ...templates,
    }
  })
