import { apiClient } from "App/components/services/api"
import { createEffect, createEvent, createStore } from "effector-root"

type User = {
  id: number
  email: string
  name: string
  mxney_staff: boolean
}

type Store = {
  user: User
  isAuthError: boolean
}

const defaultStore: Store = {
  user: {
    id: 0,
    email: "",
    name: "",
    mxney_staff: false,
  },
  isAuthError: false,
}

export const setUser = createEvent<User>()
export const resetUser = createEvent()

type authParams = { sentEmail: string; password: string }

export const authorization = createEffect<authParams, User>(async ({ sentEmail, password }) => {
  await apiClient.get("/sanctum/csrf-cookie")
  const { data } = await apiClient.post("/api/login", {
    email: sentEmail,
    password: password,
  })
  return data.data
})

export const authStore = createStore(defaultStore)
  .on(setUser, (store, user) => ({
    ...store,
    user,
  }))
  .on(authorization.done, (store, { result }) => ({
    ...store,
    user: {
      ...result,
    },
    isAuthError: false,
  }))
  .on(authorization.fail, (store) => ({
    ...store,
    isAuthError: true,
  }))
  .reset(resetUser)
