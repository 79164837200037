import React from "react"
import * as FeatherIcon from "react-feather"
import { useHistory } from "react-router-dom"

import { colorFromType } from "."
import { ChatTypes, GP_SERVICE_TYPE } from "./types"

type Props = {
  selectedChat: ChatTypes
}

export const ChatHeader = ({ selectedChat }: Props) => {
  const history = useHistory()

  const selectedChatClose = () => history.push("/")

  const uiAvatar = `https://eu.ui-avatars.com/api/?name=${selectedChat.name}&background=007389&color=fff`

  return (
    <div className="chat-header">
      <div className="chat-header__inner-wrap">
        <div className="chat-header-user">
          <figure className="avatar">
            <img src={uiAvatar} className="rounded-circle" alt="avatar" />
          </figure>
          <div>
            <h5>{selectedChat.name}</h5>
          </div>
          <div
            className="group-type__label"
            style={{ backgroundColor: colorFromType[selectedChat.type] }}
          >
            <p>{selectedChat.type === GP_SERVICE_TYPE ? "GP Service" : selectedChat.type}</p>
          </div>
          <div className="chat-header-action">
            <ul className="list-inline">
              <li className="list-inline-item d-xl-none d-inline">
                <button
                  onClick={selectedChatClose}
                  className="btn btn-outline-light text-danger mobile-navigation-button"
                >
                  <FeatherIcon.X />
                </button>
              </li>
              {/*
  						<li className="list-inline-item">
  								<VoiceCallModal/>
  						</li>
  						<li className="list-inline-item">
  								<VideoCallModal/>
  						</li>
  					*/}
              <li className="list-inline-item" data-toggle="tooltip" title="Video call">
                {/*
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownOpen}
                >
                  <button className="btn btn-outline-light">
                    <FeatherIcon.MoreHorizontal />
                  </button>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={profileActions}>Profile</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              */}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="chat-header__inner-wrap">
        {selectedChat.type.toLowerCase() === "growth pack service" && (
          <p className="chat-header__description">
            <span>Scope of work</span>: {selectedChat.description}
          </p>
        )}
        {selectedChat.type.toLowerCase() !== "growth pack service" && (
          <p className="chat-header__description">
            <span>Description</span>: {selectedChat.description}
          </p>
        )}
      </div>
    </div>
  )
}
