import styled from "@emotion/styled"
import { authStore } from "App/SignIn/store"
import { useStore } from "effector-react"
import React, { useCallback } from "react"

import { DefaultMessages } from "./DefaultMessages"
import { ToolBar } from "./ToolBar"
import { readMessages, SendingValue, sendMessage } from "./api"
import { chatStore, setOutgoingMessage } from "./store"
import { Message } from "./types"

export const ChatFooter = () => {
  const { selectedChat } = useStore(chatStore)
  const { user } = useStore(authStore)

  const sendMessageHandler = useCallback(
    (newValue: SendingValue) => {
      sendMessage(selectedChat.id, newValue).then(({ data }: { data: { data: Message } }) => {
        setOutgoingMessage({ ...data.data, group_id: selectedChat.id })
      })
    },
    [selectedChat.id]
  )

  return (
    <ChatFooterWrap>
      <ToolBar
        sendMessage={sendMessageHandler}
        onFocus={() => readMessages(selectedChat.id)}
        defaultMessages={<>{user.mxney_staff && <DefaultMessages />}</>}
      />
    </ChatFooterWrap>
  )
}

const ChatFooterWrap = styled.div`
  border-top: 1px solid #e6e6e6;
  padding: 17px 30px;

  @media (max-width: 1200px) {
    padding-bottom: 17px;
  }
`
