import React from "react"
import { useSelector } from "react-redux"

import { Archived } from "./Archived"
import { ChatsList } from "./ChatsList"
import { Favorites } from "./Favorites"
import { Friends } from "./Friends"

export const Sidebar = () => {
  const { selectedSidebar, mobileSidebar } = useSelector((state: any) => state)

  return (
    <div className={`sidebar-group ${mobileSidebar ? "mobile-open" : ""}`}>
      {(() => {
        if (selectedSidebar === "Chats") {
          return <ChatsList />
        } else if (selectedSidebar === "Friends") {
          return <Friends />
        } else if (selectedSidebar === "Favorites") {
          return <Favorites />
        } else if (selectedSidebar === "Archived") {
          return <Archived />
        }
      })()}
    </div>
  )
}
