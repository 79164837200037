export const OUTGOING_MESSAGE = "outgoing-message"
export const INCOMING_MESSAGE = "incoming-message"
export const DIVIDER = "divider"
export const OUTGOING_TEXT_MESSAGE = "outgoing-text-message"
export const OUTGOING_AUDIO_MESSAGE = "outgoing-audio-message"
export const OUTGOING_VIDEO_MESSAGE = "outgoing-video-message"
export const OUTGOING_FILE_MESSAGE = "outgoing-file-message"
export const TEXT_MESSAGE = "text-message"
export const AUDIO_MESSAGE = "audio-message"
export const VIDEO_MESSAGE = "video-message"
export const FILE_MESSAGE = "file-message"
export const GP_SERVICE_TYPE = "growth pack service"
export const GROWTH_TYPE = "growth"
export const FINANCE_TYPE = "finance"
export const LEGAL_TYPE = "legal"
export const TECH_TYPE = "tech"

export type MessageType =
  | ""
  | typeof OUTGOING_MESSAGE
  | typeof INCOMING_MESSAGE
  | typeof DIVIDER
  | typeof OUTGOING_TEXT_MESSAGE
  | typeof OUTGOING_AUDIO_MESSAGE
  | typeof OUTGOING_VIDEO_MESSAGE
  | typeof OUTGOING_FILE_MESSAGE

export type GroupType =
  | ""
  | typeof GP_SERVICE_TYPE
  | typeof GROWTH_TYPE
  | typeof FINANCE_TYPE
  | typeof LEGAL_TYPE
  | typeof TECH_TYPE

export type AttachmentsData = {
  id: number
  name: string
  url: string
  download: string
  date: Date
  meta_data: {
    fileName: string
    fileSize: string
    fileExtension: string
  }
}

export type MediaTypes =
  | typeof TEXT_MESSAGE
  | typeof AUDIO_MESSAGE
  | typeof VIDEO_MESSAGE
  | typeof FILE_MESSAGE
export type Message = {
  id: number
  name: string
  avatar: string
  text: string
  date: Date
  type: MessageType
  media: MediaTypes
  attachments: {
    data: AttachmentsData[]
  }
  group_id?: number
}

export type ChatTypes = {
  avatar: string
  date: Date
  id: number
  messages: Message[]
  name: string
  text: string
  type: GroupType
  unread_messages: number
  description?: string
}

export type ChatsListTypes = ChatTypes[]
