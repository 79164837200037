import { Select } from "App/components/ui/Select"
import { TooltipButton } from "App/components/ui/TooltipButton"
import { useStore } from "effector-react"
import React, { useCallback } from "react"
import * as FeatherIcon from "react-feather"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroup,
} from "reactstrap"

import { GroupMember } from "./GroupMember"
import {
  createGroup,
  createGroupModalStore,
  resetGroup,
  setFirstMessage,
  setGroupName,
  setGroupDescription,
  setGroupType,
  setValidation,
  toggleGroupModal,
  updateExistingGroup,
} from "./store"

export type selectItem = {
  label: string
  value: string
}

export const groupTypes: selectItem[] = [
  { label: "All", value: "" },
  { label: "Growth Pack Service", value: "Growth Pack Service" },
  { label: "Growth", value: "Growth" },
  { label: "Finance", value: "Finance" },
  { label: "Legal", value: "Legal" },
  { label: "Tech", value: "Tech" },
]

export const GroupModal = () => {
  const {
    isGroupModalOpen,
    usersInGroup,
    groupName,
    firstMessage,
    type,
    description,
    validation,
    groupId,
  } = useStore(createGroupModalStore)

  const closeModal = useCallback(() => {
    toggleGroupModal(false)
    resetGroup()
  }, [])

  const onTypeChange = useCallback((type: { label: string; value: string }) => {
    setGroupType(type)
  }, [])

  const createGroupHandler = () => {
    const isUserInGroup = usersInGroup.some(({ id }) => id)
    setValidation({
      groupName: !!groupName,
      firstMessage: !!firstMessage,
      usersInGroup: isUserInGroup,
      description: description.length <= 100,
      type: !!type,
    })

    if (groupName && firstMessage && isUserInGroup) {
      createGroup({ usersInGroup, groupName, firstMessage, type, description })
      closeModal()
    }
  }

  const updateGroupHandler = (groupId: number) => {
    const isUserInGroup = usersInGroup.some(({ id }) => id)
    setValidation({
      groupName: !!groupName,
      firstMessage: true,
      usersInGroup: isUserInGroup,
      description: description.length <= 100,
      type: !!type,
    })
    if (groupName && isUserInGroup) {
      updateExistingGroup({ usersInGroup, groupName, groupId, type, description })
      closeModal()
    }
  }

  return (
    <div>
      <TooltipButton
        onClick={() => toggleGroupModal(true)}
        id="Tooltip-Add-Group"
        tooltip="Add group"
        icon={<FeatherIcon.Users />}
        className="btn btn-outline-light"
      />
      <Modal className="modal-dialog-zoom" isOpen={isGroupModalOpen} toggle={closeModal} centered>
        <ModalHeader toggle={closeModal}>
          <FeatherIcon.UserPlus className="mr-2" /> Add Group
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Group name</Label>
              <InputGroup>
                <Input
                  className={validation.groupName ? "" : "input-error"}
                  type="text"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label for="group_name">The group members</Label>
              {usersInGroup.map(({ id, name, label }, i, array) => (
                <GroupMember
                  key={i}
                  isFirst={i === array.length - 1}
                  name={name}
                  id={id}
                  index={i}
                  label={label}
                  isError={!validation.usersInGroup}
                />
              ))}
            </FormGroup>

            <FormGroup>
              <Label>Group type</Label>
              <Select
                selectedOption={type}
                onChange={onTypeChange}
                placeholder="Select group type"
                options={groupTypes}
              />
            </FormGroup>

            {groupId === null && (
              <FormGroup>
                <Label for="invitation">Invitation message</Label>
                <Input
                  type="textarea"
                  value={firstMessage}
                  onChange={(e) => setFirstMessage(e.target.value)}
                  className={validation.firstMessage ? "" : "input-error"}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="description">Group description</Label>
              <InputGroup>
                <Input
                  type="textarea"
                  value={description}
                  onChange={(e) => setGroupDescription(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              groupId === null ? createGroupHandler() : updateGroupHandler(groupId)
            }}
          >
            {groupId === null ? "Add" : "Update"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
