import styled from "@emotion/styled"
import { ToolBar } from "App/Chat/ToolBar"
import { getMessageTemplate, SendingValue, sendMessageTemplate } from "App/Chat/api"
import { TooltipButton } from "App/components/ui/TooltipButton"
import React, { useCallback, useEffect, useState } from "react"
import * as FeatherIcon from "react-feather"

import { DefaultMessagesBody } from "./DefaultMessagesBody"
import { setDefaultMessages, setDefaultOutgoingMessage, TemplateMessage } from "./store"

export const DefaultMessages = () => {
  const [isShow, toggleSidebar] = useState(false)

  useEffect(() => {
    getMessageTemplate()
      .then(({ data }) => {
        setDefaultMessages(data.data)
      })
      .catch((e) => {
        console.log("🚀 ~ error", e)
      })
  }, [])

  const saveDefaultMessage = useCallback((newValue: SendingValue) => {
    sendMessageTemplate(newValue).then(({ data }: { data: { data: TemplateMessage } }) => {
      setDefaultOutgoingMessage(data.data)
    })
  }, [])

  return (
    <>
      <TooltipButton
        onClick={() => toggleSidebar(true)}
        id="default-messages"
        icon={<FeatherIcon.MessageSquare />}
        tooltip="Show Default Messages"
        color="link"
      />
      <Wrap className={`sidebar-group ${isShow ? "active" : ""}`}>
        <div className="sidebar default-messages active">
          <header>
            <span className="sidebar-title">Library</span>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a
                  onClick={() => toggleSidebar(false)}
                  className="btn btn-outline-light text-danger sidebar-close"
                >
                  <FeatherIcon.X />
                </a>
              </li>
            </ul>
          </header>
          <DefaultMessagesBody />
          <FooterWrap>
            <ToolBar sendMessage={saveDefaultMessage} />
          </FooterWrap>
        </div>
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  visibility: hidden;
  position: absolute;
  height: 100%;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  align-items: flex-end;

  &.active {
    visibility: visible;
  }
`

const FooterWrap = styled.div`
  border-top: 1px solid #e6e6e6;
  padding: 17px 30px;
`
