import styled from "@emotion/styled"
import { ReactComponent as Loader } from "assets/img/svg-loaders/oval.svg"
import React from "react"

export const Preloader = () => (
  <Wrap>
    <Loader stroke="#000" />
  </Wrap>
)

const Wrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
