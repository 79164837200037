import { chatStore } from "App/Chat/store"
import { ChatTypes } from "App/Chat/types"
import { GroupModal } from "App/GroupModal"
import { FilterGroupsModal } from "App/Modals/FilterGroupsModal"
import { authStore } from "App/SignIn/store"
import { useStore } from "effector-react"
import React, { useEffect, useState } from "react"
import * as FeatherIcon from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import { ChatPreview } from "./ChatPreview"

export const ChatsList = () => {
  const { chatsList } = useStore(chatStore)
  const { user } = useStore(authStore)

  const [filteredChats, setFilteredChats] = useState<ChatTypes[]>([])
  const [searchValue, setSearchValue] = useState("")
  const [filterValue, setFilterValue] = useState({ label: "", value: "" })
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)

  const mobileMenuBtn = () => document.body.classList.toggle("navigation-open")

  const getChats = (search: string, filter: string) => {
    const filteredChats = chatsList.filter((chat) => {
      const searchRes = chat.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      if (filter) {
        return chat.type.toLowerCase() === filter.toLowerCase() && searchRes
      }
      return searchRes
    })
    setFilteredChats(filteredChats)
  }

  useEffect(() => {
    getChats(searchValue, filterValue.value)
  }, [chatsList, searchValue, filterValue.value])

  const toggleFilterModal = () => setIsFilterModalOpen((prev) => !prev)

  return (
    <>
      <div className="sidebar active">
        <header>
          <div className="d-flex align-items-center">
            <button
              onClick={mobileMenuBtn}
              className="btn btn-outline-light mobile-navigation-button mr-3 d-xl-none d-inline"
            >
              <FeatherIcon.Menu />
            </button>
            <span className="sidebar-title">Chats</span>
          </div>
          <ul className="list-inline">
            {user.mxney_staff && (
              <li className="list-inline-item">
                <GroupModal />
              </li>
            )}
            <li className="list-inline-item"></li>
          </ul>
        </header>
        <form>
          <input
            type="text"
            className="form-control"
            placeholder="Search chats"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <button className="list-inline__filter-button" type="button" onClick={toggleFilterModal}>
            <FeatherIcon.Filter />
          </button>
        </form>
        <div className="sidebar-body">
          <PerfectScrollbar>
            <ul className="list-group list-group-flush">
              {filteredChats.map((chat: ChatTypes, i: number) => (
                <ChatPreview chat={chat} key={i} />
              ))}
            </ul>
          </PerfectScrollbar>
        </div>
      </div>
      <FilterGroupsModal
        isOpen={isFilterModalOpen}
        toggle={toggleFilterModal}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
      />
    </>
  )
}
