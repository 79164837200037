import { TooltipButton } from "App/components/ui/TooltipButton"
import React from "react"

type StartStopProps = {
  isCapturing: boolean
  start: () => void
  stop: () => void
}

export const StartStopButtons = ({ isCapturing, start, stop }: StartStopProps) =>
  isCapturing ? (
    <TooltipButton onClick={stop} text="Stop Recording" />
  ) : (
    <TooltipButton onClick={start} text="Start Recording" />
  )
