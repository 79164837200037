import styled from "@emotion/styled"
import React, { FC, useState } from "react"

const dateCreator = (date: Date) => {
  const addZero = (value: number) => (value > 9 ? "" : "0") + value

  const month = new Date(date).getMonth() + 1
  const day = new Date(date).getDate()
  const hour = new Date(date).getHours()
  const min = new Date(date).getMinutes()

  return `${addZero(day)}/${addZero(month)}/${new Date(date).getFullYear()}, ${addZero(
    hour
  )}:${addZero(min)}`
}

type Props = {
  date: Date
  isOutgoingMessage: boolean
  className?: string
  stopHover?: boolean
}

export const MessageHover: FC<Props> = ({
  children,
  date,
  isOutgoingMessage,
  className,
  stopHover,
}) => {
  const [messageDate, setDate] = useState("")
  const [overTimeout, setOverTimeout] = useState<any>(null)
  const [onTimeout, setOnTimeout] = useState<any>(null)

  const onMouseOver = () => {
    clearTimeout(onTimeout)
    setOnTimeout(null)
    if (!overTimeout) {
      const timeout = setTimeout(() => {
        setDate(dateCreator(date))
      }, 1500)

      setOverTimeout(timeout)
    }
  }

  const onMouseOut = () => {
    if (!messageDate) {
      clearTimeout(overTimeout)
      setOverTimeout(null)
      return
    }
    const timeout = setTimeout(() => {
      setDate("")
      clearTimeout(overTimeout)
      setOverTimeout(null)
    }, 1500)
    setOnTimeout(timeout)
  }

  return (
    <MessageContent
      className={`message-content ${className}`}
      onMouseOver={() => !stopHover && onMouseOver()}
      onMouseOut={() => !stopHover && onMouseOut()}
    >
      {children}
      <MessageDate
        className="message-date"
        isShow={!!messageDate}
        isOutgoingMessage={isOutgoingMessage}
      >
        {messageDate}
      </MessageDate>
    </MessageContent>
  )
}

const MessageDate = styled.div`
  position: absolute;
  bottom: 0;
  transition: all 1s;
  white-space: nowrap;
  font-style: italic;
  font-size: 12px;

  ${({ isShow, isOutgoingMessage }: { isShow: boolean; isOutgoingMessage: boolean }) => {
    if (!isOutgoingMessage) {
      return isShow
        ? "right: 0; transform: translateX(110%); opacity: 1;"
        : "right: 0; transform: translateX(0); opacity: 0;"
    }
    return isShow
      ? "left: 0; transform: translateX(-110%); opacity: 1;"
      : "left: 0; transform: translateX(0); opacity: 0;"
  }};
`

const MessageContent = styled.div`
  position: relative;
`
