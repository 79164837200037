import styled from "@emotion/styled"
import { DARK } from "App/Chat/Navigation"
import { useStore } from "effector-react"
import React, { FormEvent, useEffect, useState } from "react"
import * as FeatherIcon from "react-feather"
import { useHistory } from "react-router-dom"

import { authorization, authStore } from "./store"

export const SignIn = () => {
  const history = useHistory()
  const { isAuthError } = useStore(authStore)

  useEffect(() => {
    const bodyClassList = document.body.classList
    bodyClassList.remove(DARK)
    bodyClassList.add("form-membership")
  }, [])

  const [sentEmail, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    authorization({ sentEmail, password })
      .then(() => {
        history.push("/")
        document.body.classList.remove("form-membership")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className="mxney-logo">
        <img src="https://mxney.io/assets/img/mxney-logo-x2.png" />
      </div>
      <div className="form-wrapper">
        <h6>Use your MXNEY Board login</h6>
        <h5>Sign in</h5>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={sentEmail}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <button className="btn btn-dark-black btn-block">
            {loading ? <FeatherIcon.Loader className="btn-loading-icon" /> : ""}
            &nbsp; LOGIN
          </button>
        </form>
        <ErrorMessage isAuthError={isAuthError}>Incorrect email or password</ErrorMessage>
      </div>
    </>
  )
}

const ErrorMessage = styled.div`
  visibility: ${({ isAuthError }: { isAuthError: boolean }) =>
    isAuthError ? "visible" : "hidden"};
  color: #fd397a;
  margin-top: 12px;
  height: 22px;
`
