import styled from "@emotion/styled"
import { SendingValue } from "App/Chat/api"
import { OUTGOING_AUDIO_MESSAGE } from "App/Chat/types"
import { TooltipButton } from "App/components/ui/TooltipButton"
import { useStore } from "effector-react"
import React, { useEffect, useState } from "react"
import { Mic as MicIcon } from "react-feather"

import { ErrorModalBody } from "./ErrorModalBody"
import { MessageModal } from "./MessageModal"
import { StartStopButtons } from "./StartStopButtons"
import { SendMedia, sendMedia } from "./api"
import { checkMediaAvailable, mediaStore } from "./store"

type Props = {
  onSubmit: (newValue: SendingValue) => void
}

const AudioCapture = ({
  onSubmit,
  showModal,
}: Props & { showModal: (isModal: boolean) => void }) => {
  const [recording, setRecording] = useState<MediaRecorder | null>(null)
  const [blob, setBlob] = useState<Blob>()
  const [src, setSrc] = useState("")

  useEffect(() => {
    if (blob) {
      setSrc(window.URL.createObjectURL(blob))
    }
  }, [blob])

  const doneRecording = ({ uuid, ext }: SendMedia) => {
    const attachments = [
      {
        name: uuid[0],
        meta_data: {
          fileName: uuid[0],
          fileSize: "",
          fileExtension: ext || "",
        },
      },
    ]

    onSubmit({
      message: uuid[0],
      type: OUTGOING_AUDIO_MESSAGE,
      attachments,
    })
  }

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    const audioChunks: Blob[] = []

    const rec = new MediaRecorder(stream)
    rec.ondataavailable = ({ data }: { data: Blob }) => {
      audioChunks.push(data)
      if (rec.state == "inactive") {
        stream.getAudioTracks().forEach((track) => {
          track.stop()
        })

        setBlob(new Blob(audioChunks, { type: "audio/mp3" }))
      }
    }

    rec.start()
    setRecording(rec)
  }

  const endRecording = () => {
    recording?.stop()
  }

  const sendRecording = () => {
    blob && sendMedia([blob], doneRecording, "mp3")
    showModal(false)
  }

  return (
    <div className="call">
      {src ? (
        <Preview controls>
          <source src={src} type="audio/mp3" />
        </Preview>
      ) : (
        <Wrap>
          <figure className="avatar avatar-xl mb-4">
            <MicIcon size={100} />
          </figure>
          <h4>
            <span className="text-success">Recoding...</span>
          </h4>
        </Wrap>
      )}
      {!!blob ? (
        <TooltipButton onClick={sendRecording} text="Send Audio" />
      ) : (
        <StartStopButtons start={startRecording} stop={endRecording} isCapturing={!!recording} />
      )}
    </div>
  )
}

const AudioModalBody = ({ onSubmit, showModal }: Props & { showModal: any }) => {
  const { audio } = useStore(mediaStore)

  useEffect(() => {
    !audio && checkMediaAvailable({ audio: true, video: false })
  }, [])

  if (audio === null) {
    return null
  }

  return audio ? <AudioCapture onSubmit={onSubmit} showModal={showModal} /> : <ErrorModalBody />
}

export const AudioMessageModal = ({ onSubmit }: Props) => {
  const [isModal, toggleModal] = useState(false)

  return (
    <MessageModal
      id="Tooltip-Voice-Message"
      icon={<MicIcon />}
      tooltip="Audio Message"
      isModal={isModal}
      toggleModal={toggleModal}
    >
      <AudioModalBody showModal={toggleModal} onSubmit={onSubmit} />
    </MessageModal>
  )
}

const Wrap = styled.div`
  margin-bottom: 16px;
`

const Preview = styled.video`
  width: 100%;
  height: 60px;
  margin-bottom: 16px;
`
