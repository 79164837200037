import { apiClient } from "App/components/services/api"
import { Preloader } from "App/components/ui/Preloader"
import React, { useEffect, useState } from "react"
import { Redirect, useLocation } from "react-router-dom"

export const InstantLogin = () => {
  const [isAuthenticated, setAuthenticated] = useState<boolean | null>(null)

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const token = params.get("token")

  const authenticated = async () => {
    await apiClient.get("/sanctum/csrf-cookie")
    apiClient
      .post(
        "/api/instantlogin",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setAuthenticated(true)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setAuthenticated(false)
        }
      })
  }

  useEffect(() => {
    authenticated()
  }, [])

  if (isAuthenticated === null) {
    return <Preloader />
  }

  return <>{isAuthenticated ? <Redirect to="/" /> : <Redirect to="/login" />}</>
}
