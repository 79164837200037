import { apiUrl } from "App/components/routes"
import axios from "axios"
import Echo from "laravel-echo"
import Pusher from "pusher-js"

export { Pusher }

const headers = {
  "Content-Type": "application/json",
}

const options = {
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: "eu",
  authEndpoint: `${apiUrl}/broadcasting/auth`,
  authorizer: (channel: { name: string }) => ({
    authorize: (socketId: number | string, callback: (value: boolean, data: any) => void) => {
      axios
        .post(
          `${apiUrl}/broadcasting/auth`,
          {
            socket_id: socketId,
            channel_name: channel.name,
          },
          { headers, withCredentials: true }
        )
        .then((response) => {
          callback(false, response.data)
        })
        .catch((error) => {
          callback(true, error)
        })
    },
  }),
}

export const echo = new Echo(options)
