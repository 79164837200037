import { Chat } from "App/Chat"
import { resetUser, setUser } from "App/SignIn/store"
import { apiClient } from "App/components/services/api"
import { Preloader } from "App/components/ui/Preloader"
import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect } from "react-router-dom"

export const AuthenticatedContext = () => {
  const [isAuthenticated, setAuthenticated] = useState<boolean | null>(null)

  useEffect(() => {
    apiClient
      .get("/api/user")
      .then(({ data }) => {
        setUser(data.data)
        setAuthenticated(true)
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          setAuthenticated(false)
        }

        resetUser()
      })
  }, [])

  if (isAuthenticated === null) {
    return <Preloader />
  }

  return (
    <>
      {!isAuthenticated && <Redirect to="/login" />}
      <Switch>
        <Route exact path={["/", "/:id"]} component={Chat} />
      </Switch>
    </>
  )
}
