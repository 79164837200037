import { colorFromType } from "App/Chat"
import { getMessages, readMessages } from "App/Chat/api"
import { updateSelectedChat } from "App/Chat/store"
import { ChatTypes, GP_SERVICE_TYPE } from "App/Chat/types"
import { authStore } from "App/SignIn/store"
import { useStore } from "effector-react"
import React, { useCallback, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"

import { ChatsDropdown } from "./ChatsDropdown"

type Props = {
  chat: ChatTypes
} & RouteComponentProps<{ id?: string }>

export const ChatPreview = withRouter(({ chat, match, history }: Props) => {
  const isCurrentChat = chat.id === Number(match.params.id)
  const { user } = useStore(authStore)

  const chatDate = (chat: ChatTypes) => {
    const date = new Date(chat.date)
    return date.toISOString().substring(0, 10)
  }

  useEffect(() => {
    isCurrentChat &&
      getMessages(1, Number(match.params.id)).then(({ data }) => {
        updateSelectedChat({ chat, messages: data.data })
      })
  }, [match.params.id])

  const chatSelectHandle = useCallback(() => {
    readMessages(chat.id)
    history.push(`/${chat.id}`)
  }, [chat.id])

  return (
    <li
      className={"list-group-item " + (isCurrentChat ? "open-chat" : "")}
      onClick={chatSelectHandle}
    >
      <figure className="avatar">
        <img
          src={`https://eu.ui-avatars.com/api/?name=${chat.name}&background=007389&color=fff`}
          className="rounded-circle"
          alt="avatar"
        />
      </figure>
      <div className="users-list-body">
        <div>
          <div className="users-list-body__inner-wrap">
            <h5 className={chat.unread_messages ? "text-primary" : ""}>{chat.name}</h5>
            <div
              className="group-type__label"
              style={{ backgroundColor: colorFromType[chat.type] }}
            >
              <p className="group-type__text">
                {chat.type === GP_SERVICE_TYPE ? "GP Service" : chat.type}
              </p>
            </div>
          </div>
          <p className={chat.unread_messages ? "padding-right" : ""}>{chat.text}</p>
        </div>
        <div className="users-list-action">
          {chat.unread_messages ? (
            <div className="new-message-count">{chat.unread_messages}</div>
          ) : (
            ""
          )}
          <small className={chat.unread_messages ? "text-primary" : "text-muted"}>
            {chatDate(chat)}
          </small>
          {user.mxney_staff && (
            <div className="action-toggle">
              <ChatsDropdown groupId={chat.id} />
            </div>
          )}
        </div>
      </div>
    </li>
  )
})
