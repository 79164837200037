import { apiClient } from "App/components/services/api"
import { echo } from "App/components/services/webscokets"
import audioMessageTone from "assets/audio/message.mp3"
import { createStore, createEffect, createEvent, sample } from "effector"

import { getMessages } from "./api"
import { ChatTypes, ChatsListTypes, Message } from "./types"

type Store = {
  currentPage: number
  chatsList: ChatsListTypes
  selectedChat: ChatTypes
  isVideoMessageSupported: boolean
}

const defaultStore: Store = {
  currentPage: 1,
  chatsList: [],
  selectedChat: {
    avatar: "",
    date: new Date(),
    id: 0,
    messages: [],
    name: "",
    text: "",
    type: "",
    unread_messages: 0,
  },
  isVideoMessageSupported: false,
}

const audio = new Audio(audioMessageTone)

export const chatStore = createStore(defaultStore)

export const setOutgoingMessage = createEvent<Message>()
export const updateSelectedChat = createEvent<{ chat: ChatTypes; messages: Message[] }>()
export const updateUnreadMessage = createEvent<{ groupId: number; isAdd?: boolean }>()
export const checkVideoSupported = createEvent()
const setIncomingMessage = createEvent<Message>()
const playAlert = createEvent<number>()
export const resetChat = createEvent()

export const subscribeToChats = (chatsList: ChatsListTypes) =>
  chatsList.forEach(({ id }: { id: number }) => {
    echo.private(`groups.${id}`).listen("NewMessage", (mess: Message & { group_id: number }) => {
      updateUnreadMessage({ groupId: mess.group_id, isAdd: true })
      setIncomingMessage({ ...mess, group_id: id })
      playAlert(id)
    })
  })

export const getNextPage = createEffect({
  handler: (id: number) => {
    const { currentPage } = chatStore.getState()

    return getMessages(currentPage + 1, id)
  },
})

export const chatInit = createEffect({
  handler: async () => {
    const { data }: { data: { data: ChatsListTypes } } = await apiClient.get("/api/groups")
    const chatsList = data.data
    subscribeToChats(chatsList)

    return chatsList.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  },
})

sample({
  clock: playAlert,
  source: chatStore,
  fn: ({ selectedChat }, groupId) => {
    selectedChat.id !== groupId && audio.play()
  },
})

const setMessageToChatList = (chatsList: ChatsListTypes, { group_id, date, text }: Message) =>
  chatsList.reduce(
    (accum: ChatsListTypes, chatPreview) =>
      chatPreview.id === group_id
        ? [
            {
              ...chatPreview,
              date,
              text,
            },
            ...accum,
          ]
        : [...accum, chatPreview],
    []
  )

chatStore
  .on(getNextPage.done, (store, { result }) => ({
    ...store,
    currentPage: store.currentPage + 1,
    selectedChat: {
      ...store.selectedChat,
      messages: [...result.data.data.reverse(), ...store.selectedChat.messages],
    },
  }))
  .on(chatInit.done, (store, { result }) => ({
    ...store,
    chatsList: result,
  }))
  .on(updateSelectedChat, (store, { chat, messages }) => ({
    ...store,
    currentPage: 1,
    selectedChat: {
      ...chat,
      messages: messages.reverse(),
      unread_messages: 0,
    },
  }))
  .on(setOutgoingMessage, (store, message) => ({
    ...store,
    chatsList: setMessageToChatList(store.chatsList, message),
    selectedChat: {
      ...store.selectedChat,
      date: message.date,
      messages: [...store.selectedChat.messages, message],
    },
  }))
  .on(setIncomingMessage, (store, newMessage) => ({
    ...store,
    chatsList: setMessageToChatList(store.chatsList, newMessage),
    selectedChat:
      newMessage.group_id === store.selectedChat.id
        ? {
            ...store.selectedChat,
            date: newMessage.date,
            messages: [...store.selectedChat.messages, newMessage],
          }
        : store.selectedChat,
  }))
  .on(updateUnreadMessage, (store, { groupId, isAdd }) => {
    if (store.selectedChat.id !== groupId) {
      return {
        ...store,
        chatsList: store.chatsList.map((chat) =>
          chat.id === groupId
            ? {
                ...chat,
                unread_messages: isAdd ? chat.unread_messages + 1 : 0,
              }
            : chat
        ),
      }
    }
  })
  .on(checkVideoSupported, (store) => ({
    ...store,
    isVideoMessageSupported: MediaRecorder.isTypeSupported("video/webm"),
  }))
  .reset(resetChat)
