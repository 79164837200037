import { MessageHover } from "App/Chat/MessagesView/MessageHover"
import { AUDIO_MESSAGE, Message, VIDEO_MESSAGE } from "App/Chat/types"
import imageExtensions from "image-extensions"
import React, { useState } from "react"

import { FileView } from "./FileView"
import { ImageView } from "./ImageView"
import { errorHandler } from "./handlers"

type MediaProps = {
  date: Date
  isOutgoingMessage: boolean
  url: string
  stopHover?: boolean
}

const AudioView = ({ date, isOutgoingMessage, url, stopHover }: MediaProps) => {
  const [key, updateKey] = useState(0)

  return (
    <MessageHover stopHover={stopHover} date={date} isOutgoingMessage={isOutgoingMessage} key={key}>
      <audio controls onError={() => errorHandler(key, updateKey)}>
        <source src={url} type="audio/mp3" />
      </audio>
    </MessageHover>
  )
}

const VideoView = ({ date, isOutgoingMessage, url, stopHover }: MediaProps) => {
  const [key, updateKey] = useState(0)

  return (
    <MessageHover stopHover={stopHover} date={date} isOutgoingMessage={isOutgoingMessage} key={key}>
      <video width="320" height="240" controls muted onError={() => errorHandler(key, updateKey)}>
        <source src={url} type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </MessageHover>
  )
}

type Props = {
  message: Message
  isOutgoingMessage: boolean
  stopHover?: boolean
}

export const MediaMessage = ({ message, isOutgoingMessage, stopHover }: Props) => {
  switch (message.media) {
    case AUDIO_MESSAGE:
      return !!message.attachments.data.length ? (
        <AudioView
          date={message.date}
          isOutgoingMessage={isOutgoingMessage}
          url={message.attachments.data[0].url}
          stopHover={stopHover}
        />
      ) : null
    case VIDEO_MESSAGE:
      return !!message.attachments.data.length ? (
        <VideoView
          date={message.date}
          isOutgoingMessage={isOutgoingMessage}
          url={message.attachments.data[0].url}
          stopHover={stopHover}
        />
      ) : null
    default:
      return (
        <>
          {message.attachments.data.map(({ id, date, url, download, meta_data }) => {
            const isImage = imageExtensions.includes(meta_data.fileExtension)
            return isImage ? (
              <ImageView
                key={id}
                download={download}
                url={url}
                fileName={meta_data.fileName}
                isOutgoingMessage={isOutgoingMessage}
                date={date}
                fileSize={meta_data.fileSize}
                stopHover={stopHover}
              />
            ) : (
              <FileView
                key={id}
                download={download}
                fileName={meta_data.fileName}
                isOutgoingMessage={isOutgoingMessage}
                date={date}
                fileSize={meta_data.fileSize}
                stopHover={stopHover}
              />
            )
          })}
        </>
      )
  }
}
