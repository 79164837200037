import { SignIn } from "App/SignIn"
import React from "react"
import { Switch, Route } from "react-router-dom"

import { AuthenticatedContext } from "./AuthenticatedContext"
import { InstantLogin } from "./InstantLogin"
import { ResetPassword, PhoneCode } from "./Pages"

export const routes = (
  <Switch>
    <Route path="/login" component={SignIn} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/phone-code" component={PhoneCode} />
    <Route path="/instantlogin" component={InstantLogin} />
    <Route component={AuthenticatedContext} />
  </Switch>
)
