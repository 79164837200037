import { DIVIDER, Message, OUTGOING_MESSAGE } from "App/Chat/types"
import React, { memo, useMemo } from "react"

import { MessageComponent } from "./MessageComponent"

type Props = {
  message: Message
  sameUser: number
  stopHover?: boolean
}

export const MessagesView = memo(({ message, sameUser, stopHover }: Props) => {
  const { type = OUTGOING_MESSAGE, text, name, date } = message
  const messageDate = (date: Date) => new Date(date).toLocaleDateString()
  const messageTime = (date: Date) => new Date(date).toLocaleTimeString()

  const isOutgoingMessage = useMemo(() => type.split("-")[0] === "outgoing", [type])

  if (type === DIVIDER) {
    return <div className="message-item messages-divider sticky-top" data-label={text}></div>
  }

  return (
    <div className={`message-item ${type} sameUser-${sameUser}`}>
      {!sameUser && (
        <div className="message-avatar">
          <figure className="avatar">
            <img
              src={`https://eu.ui-avatars.com/api/?name=${name}&background=007389&color=fff`}
              className="rounded-circle"
              alt="avatar"
            />
          </figure>
          <div>
            <h5>{name}</h5>
            <div className="time">
              {messageDate(date)} <span>{messageTime(date)}</span>
            </div>
          </div>
        </div>
      )}
      <MessageComponent
        stopHover={stopHover}
        message={message}
        isOutgoingMessage={isOutgoingMessage}
      />
    </div>
  )
})
