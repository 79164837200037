import styled from "@emotion/styled"
import { SendingValue } from "App/Chat/api"
import { chatStore } from "App/Chat/store"
import { OUTGOING_VIDEO_MESSAGE } from "App/Chat/types"
import { TooltipButton } from "App/components/ui/TooltipButton"
import { useStore } from "effector-react"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Video as VideoIcon } from "react-feather"
import Webcam from "react-webcam"

import { ErrorModalBody } from "./ErrorModalBody"
import { MessageModal } from "./MessageModal"
import { StartStopButtons } from "./StartStopButtons"
import { VideoMessageNotSupport } from "./VideoMessageNotSupport"
import { SendMedia, sendMedia } from "./api"
import { checkMediaAvailable, mediaStore } from "./store"

type Props = {
  onSubmit: (newValue: SendingValue) => void
}

type CloseModal = { closeModal: () => void }

const WebcamStreamCapture = ({ closeModal, onSubmit }: Props & CloseModal) => {
  const webcamRef = useRef<Webcam>(null)
  const mediaRecorderRef = useRef<any>(null)
  const [isCapturing, setCapturing] = useState(false)
  const [recordedChunks, setRecordedChunks] = useState([])
  const [src, setSrc] = useState("")

  useEffect(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      })

      setSrc(window.URL.createObjectURL(blob))
    }
  }, [recordedChunks.length])

  const doneRecording = ({ uuid, ext }: SendMedia) => {
    const attachments = [
      {
        name: uuid[0],
        meta_data: {
          fileName: uuid[0],
          fileSize: "",
          fileExtension: ext || "",
        },
      },
    ]

    onSubmit({
      message: uuid[0],
      type: OUTGOING_VIDEO_MESSAGE,
      attachments,
    })
  }

  const handleStartCaptureClick = useCallback(() => {
    const stream = webcamRef?.current?.stream
    setCapturing(true)

    if (stream) {
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm",
      })
    }

    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable)
    mediaRecorderRef.current.start()
  }, [webcamRef, setCapturing, mediaRecorderRef])

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data))
      }
    },
    [setRecordedChunks]
  )

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop()
    setCapturing(false)
  }, [mediaRecorderRef, webcamRef, setCapturing, recordedChunks])

  const handleSend = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      })
      sendMedia([blob], doneRecording, "webm")
      closeModal()
    }
  }, [recordedChunks])

  return (
    <>
      {src ? (
        <Preview controls>
          <source src={src} type="audio/webm" />
          Your browser does not support the video tag.
        </Preview>
      ) : (
        <WebcamWrap>
          <Webcam ref={webcamRef} mirrored={true} />
        </WebcamWrap>
      )}
      {recordedChunks.length ? (
        <TooltipButton onClick={handleSend} text="Send Video" />
      ) : (
        <StartStopButtons
          start={handleStartCaptureClick}
          stop={handleStopCaptureClick}
          isCapturing={isCapturing}
        />
      )}
    </>
  )
}

const VideoModalBody = ({ onSubmit, closeModal }: Props & CloseModal) => {
  const { audio, video } = useStore(mediaStore)
  const { isVideoMessageSupported } = useStore(chatStore)

  useEffect(() => {
    isVideoMessageSupported && !audio && !video && checkMediaAvailable({ audio: true, video: true })
  }, [])

  if (!isVideoMessageSupported) {
    return <VideoMessageNotSupport />
  }

  if (audio === null || video === null) {
    return null
  }

  return audio && video ? (
    <WebcamStreamCapture onSubmit={onSubmit} closeModal={closeModal} />
  ) : (
    <ErrorModalBody />
  )
}

export const VideoMessageModal = ({ onSubmit }: Props) => {
  const [isModal, toggleModal] = useState(false)

  return (
    <MessageModal
      id="Tooltip-Video-Message"
      icon={<VideoIcon />}
      tooltip="Video Message"
      isModal={isModal}
      toggleModal={toggleModal}
    >
      <VideoModalBody onSubmit={onSubmit} closeModal={() => toggleModal(false)} />
    </MessageModal>
  )
}

const WebcamWrap = styled.div`
  video {
    width: 100%;
  }
`

const Preview = styled.video`
  width: 100%;
  height: 100%;
`
