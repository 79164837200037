import { Message } from "App/Chat/types"
import parse from "html-react-parser"
import linkifyHtml from "linkifyjs/html"
import React from "react"

import { MessageHover } from "./MessageHover"

type Props = {
  message: Message
  isOutgoingMessage: boolean
  stopHover?: boolean
}

export const TextMessage = ({ message, isOutgoingMessage, stopHover }: Props) => (
  <MessageHover stopHover={stopHover} date={message.date} isOutgoingMessage={isOutgoingMessage}>
    {parse(
      linkifyHtml(message.text || "", {
        defaultProtocol: "https",
        target: "_blank",
      })
    )}
  </MessageHover>
)
